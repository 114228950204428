import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import style from './projectCard.module.css'

//check to swap with stateless component
class ProjectCard extends React.Component {
  state = {
    hover: true,
  }

  render() {
    return (
      <li className={style.item} id={this.props.order}>
        <Img
          alt=""
          className={style.backgroundImage}
          fluid={this.props.backgroundImage}
          style={{ position: 'absolute' }}
        />
        <div
          className={`${style.projectCard} ${style[this.props.tagColor]} ${
            this.state.hover ? style.open : style.closed
          }`}
        >
          <div className={style.cardHeader}>
            <h2>{this.props.header}</h2>
            <h3>{this.props.tag}</h3>
            <p>{this.props.desc}</p>
          </div>
          <div className={style.cardFooter}>
            <Link
              aria-disabled={this.props.disabled}
              className={`${style.link} button-two light-style`}
              data-state={this.props.active}
              to={this.props.slug}
            >
              {this.props.value}
            </Link>
          </div>
        </div>
      </li>
    )
  }
}

export default ProjectCard
