import React from 'react'
import Head from '../components/head'
import Layout from '../components/layout'
import ProjectCard from '../components/projectCard'
import TextHighlight from '../components/textHighlight'
import { graphql } from 'gatsby'
import '../styles/work.css'

export const data = graphql`
  query {
    allImageSharp(filter: { fluid: { originalName: { regex: "/lobby/" } } }) {
      edges {
        node {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___index], order: ASC }) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            tag
            description
            index
            active
            lobbyImage
          }
        }
      }
    }
  }
`

//check to swap with stateless component
class WorkPage extends React.Component {
  state = {
    projects: [],
    images: [],
    status: {
      active: 'Read More',
      inactive: 'Coming Soon',
    },
  }

  componentDidMount() {
    const lobbyImageBody = this.props.data.allImageSharp.edges.map(
      item => item.node.fluid
    )
    const markdownBody = this.props.data.allMarkdownRemark.edges
    const markdownData = markdownBody.map(item => item.node)
    const projects = [...markdownData, ...this.state.projects]
    const images = [...lobbyImageBody, ...this.state.images]
    this.setState({
      projects,
      images,
    })
  }

  render() {
    return (
      <Layout page={'Work'}>
        <Head title="Work" />
        <section className="work-header content-gutter">
          <h1>
            Some of the <br />
            <TextHighlight>projects</TextHighlight> We've Done
          </h1>
        </section>
        <section className="work-content content-gutter">
          <ul className="project-list">
            {this.state.projects.map((key, item) => (
              <ProjectCard
                active={key.frontmatter.active ? 'active' : 'inactive'}
                backgroundImage={
                  this.state.images[
                    this.state.images.findIndex(
                      image => image.originalName === key.frontmatter.lobbyImage
                    )
                  ]
                }
                desc={key.frontmatter.description}
                disabled={key.frontmatter.active ? false : true}
                header={key.frontmatter.title}
                key={key.id}
                order={item}
                value={
                  key.frontmatter.active
                    ? this.state.status.active
                    : this.state.status.inactive
                }
                slug={key.fields ? `/projects/${key.fields.slug}` : '/'}
                tag={key.frontmatter.tag}
                tagColor={
                  key.frontmatter.tag.toLowerCase() === 'branding'
                    ? 'accentPrimary'
                    : 'accentSecondary'
                }
              />
            ))}
          </ul>
        </section>
      </Layout>
    )
  }
}

export default WorkPage
